<script>
import { mapGetters } from 'vuex'
import { http } from '@/services/api.service'
import CreateDomain from '@/pages/Domains/Modals/Create.vue'

export default {
  props: ['isFullWidth'],
  computed: {
    ...mapGetters([
      'domain',
      'roles'
    ]),
    roles () {
      const roles = this.$store.getters.roles
      if (['admin', 'community-manager'].some(role => roles.includes(role))) {
        this.getDomains()
      }
      return roles
    }
  },
  data () {
    return {
      searchTerm: '',
      domains: [],
      domainsSearchResults: [],
      storageUrl: process.env.VUE_APP_STORAGE_URL
    }
  },
  mounted () {
    this.getRoles()
    if (['admin', 'community-manager'].some(role => this.roles.includes(role))) {
      this.getDomains()
    }
  },
  methods: {
    getRoles () {
      this.$store.dispatch('FETCH_ROLES')
    },
    selectDomain (domain) {
      this.$store.commit('SET_DOMAIN', domain)
      this.$emit('domainChange')
      // this.getMyRequests()
      // this.getPendingRequests()
    },
    domainRemoved (domain) {
      this.domains = this.domains.filter(d => domain.uuid !== d.uuid)
      this.selectDomain(this.domains[0])
    },
    getDomains () {
      return http.get('/domain').then(({ data }) => {
        this.domains = data
        this.domainsSearchResults = data
      }).catch(err => {
        console.log(err)
      })
    },
    createDomain () {
      this.$buefy.modal.open({
        parent: this,
        component: CreateDomain,
        hasModalCard: true,
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          created: (created) => {
            this.getDomains().finally(() => {
              this.selectDomain(created)
              this.$buefy.notification.open({
                duration: 4000,
                message: 'Le domaine a été créée avec succès !',
                position: 'is-bottom-left',
                type: 'is-success',
                hasIcon: true
              })
            })
          }
        }
      })
    },
    async handleDomainChange (domaineUuid) {
      // updating is necessary to get latest domain updates
      http.get(`/domain/${domaineUuid}`).then(({ data }) => {
        this.selectDomain(data)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<template>
  <div
    :class="{ 'w-full' : isFullWidth }"
    >
    <t-rich-select
      v-if="['admin', 'director', 'community-manager'].some(role => roles.includes(role))"
      :options="domainsSearchResults"
      placeholder="Changer de domaine"
      value-attribute="uuid"
      text-attribute="label"
      :value="domain.uuid"
      @change="handleDomainChange"
      variant="domainSelect"
    >
      <template
        slot="label"
      >
        <div class="flex bg-transparent items-center text-xs">
          <div
            class="rounded-full h-8 w-8"
            :style="`background-color: ${domain.color}`"
            v-if="domain.logo"
          >
            <img
              :src="storageUrl + '/logos/' + domain.logo"
              class="block h-8 w-8"
              alt=""
            />
          </div>
          <div class="pl-3">
            {{ domain.label }}
          </div>
        </div>
      </template>
      <template
        slot="option"
        slot-scope="{ index, isHighlighted, isSelected, className, option }"
      >
        <div class="flex p-2 text-xs">
          <div
            class="rounded-full h-4 w-4"
            :style="`background-color: ${option.raw.color}`"
            v-if="option.raw.logo"
          >
            <img
              :src="storageUrl + '/logos/' + option.raw.logo"
              class="block h-4 w-4"
              alt=""
            />
          </div>
          <div class="pl-3">
            {{ option.raw.label }}
          </div>
        </div>
      </template>

      <template slot="dropdownDown">
        <div class="p-2 text-xs w-full">
          <t-button class="w-full" @click="createDomain">
            + Créer un domaine
          </t-button>
        </div>
      </template>
    </t-rich-select>
    <div
      v-else
      class="text-white flex flex-shrink-0 items-center pr-3 border-r border-primary-200 border-opacity-25"
    >
      <div
        class="rounded-full h-8 w-8"
        :style="`background-color: ${domain.color}`"
        v-if="domain.logo"
      >
        <img
          :src="storageUrl + '/logos/' + domain.logo"
          class="block h-8 w-8"
          alt=""
        />
      </div>
      <div class="pl-3">
        {{ domain.label  }}
      </div>
    </div>
  </div>
</template>
